import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This panel lists all the contacts.`}</p>
    <p>{`The following tables list the metrics of Contacts metric panel, sorted by alphabetic order. Use `}<em parentName="p">{`CRTL + F`}</em>{` in Windows or `}<em parentName="p">{`Command + F`}</em>{` in MacOS to search inside the page.`}</p>
    <h2>{`All`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Attributed conversion value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`µ - Average`}</b></li><li><b>{`M - Maximum`}</b></li><li><b>{`m - Minimum`}</b></li><li><b>{`∑ - Sum`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bot Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Callbacks`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Callbacks canceled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Classified contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`DAvg - Daily average`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`DAvg - Daily average`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contacts with cobrowse`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`µ - Average`}</b></li><li><b>{`M - Maximum`}</b></li><li><b>{`m - Minimum`}</b></li><li><b>{`% - Percentage`}</b></li><li><b>{`∑ - Sum`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`.95 - 95th percentile`}</b></li><li><b>{`µ - Average`}</b></li><li><b>{`M - Maximum`}</b></li><li><b>{`m - Minimum`}</b></li><li><b>{`% - Percentage`}</b></li><li><b>{`∑ - Sum`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Has transcript?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Influenced conversions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`µ - Average`}</b></li><li><b>{`M - Maximum`}</b></li><li><b>{`m - Minimum`}</b></li><li><b>{`∑ - Sum`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Pending Callbacks`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`RGUs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p><b>{`∑ - Sum`}</b></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sales`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><p><b>{`# - Count`}</b></p></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Short calls`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Wait time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`.95 - 95th percentile`}</b></li><li><b>{`µ - Average`}</b></li><li><b>{`M - Maximum`}</b></li><li><b>{`m - Minimum`}</b></li><li><b>{`% - Percentage`}</b></li><li><b>{`∑ - Sum`}</b></li></ul></td>
        </tr>
      </tbody>
    </table>
    <h2>{`Chat`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Chat)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`.95 - 95th percentile`}</b></li><li><b>{`µ - Average`}</b></li><li><b>{`M - Maximum`}</b></li><li><b>{`m - Minimum`}</b></li><li><b>{`% - Percentage`}</b></li><li><b>{`∑ - Sum`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Live Chat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Nodes used`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li><li><b>{`∑ - Sum`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of interactions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`µ - Average`}</b></li><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li><li><b>{`∑ - Sum`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (Click2Chat)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time on node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li><li><b>{`∑ - Sum`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Transfer Requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Transfers with Success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Transfers without Success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (Click2Chat)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
      </tbody>
    </table>
    <h2>{`Form`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Form`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
      </tbody>
    </table>
    <h2>{`Video`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (VideoCall)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (VideoCall)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`VideoCall`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
      </tbody>
    </table>
    <h2>{`Voice`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Abandonment on queue (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Abandonment on queue (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Abandonment on queue (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Call waiting time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`.95 - 95th percentile`}</b></li><li><b>{`µ - Average`}</b></li><li><b>{`M - Maximum`}</b></li><li><b>{`m - Minimum`}</b></li><li><b>{`% - Percentage`}</b></li><li><b>{`∑ - Sum`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Call-center did not answer (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Classified contacts (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Click2Call`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Client did not answer (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Client did not answer (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact center did not answer (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact center did not answer (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`µ - Average`}</b></li><li><b>{`M - Maximum`}</b></li><li><b>{`m - Minimum`}</b></li><li><b>{`% - Percentage`}</b></li><li><b>{`∑ - Sum`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`µ - Average`}</b></li><li><b>{`M - Maximum`}</b></li><li><b>{`m - Minimum`}</b></li><li><b>{`% - Percentage`}</b></li><li><b>{`∑ - Sum`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`µ - Average`}</b></li><li><b>{`M - Maximum`}</b></li><li><b>{`m - Minimum`}</b></li><li><b>{`% - Percentage`}</b></li><li><b>{`∑ - Sum`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`.95 - 95th percentile`}</b></li><li><b>{`µ - Average`}</b></li><li><b>{`M - Maximum`}</b></li><li><b>{`m - Minimum`}</b></li><li><b>{`% - Percentage`}</b></li><li><b>{`∑ - Sum`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`.95 - 95th percentile`}</b></li><li><b>{`µ - Average`}</b></li><li><b>{`M - Maximum`}</b></li><li><b>{`m - Minimum`}</b></li><li><b>{`% - Percentage`}</b></li><li><b>{`∑ - Sum`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`.95 - 95th percentile`}</b></li><li><b>{`µ - Average`}</b></li><li><b>{`M - Maximum`}</b></li><li><b>{`m - Minimum`}</b></li><li><b>{`% - Percentage`}</b></li><li><b>{`∑ - Sum`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Human validation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Identified contacts (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Inbound`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid - Client (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid - Contact Center (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Outbound`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Short calls (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Short calls (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success classified (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time until call`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`.95 - 95th percentile`}</b></li><li><b>{`µ - Average`}</b></li><li><b>{`M - Maximum`}</b></li><li><b>{`m - Minimum`}</b></li><li><b>{`% - Percentage`}</b></li><li><b>{`∑ - Sum`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total call duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`.95 - 95th percentile`}</b></li><li><b>{`µ - Average`}</b></li><li><b>{`M - Maximum`}</b></li><li><b>{`m - Minimum`}</b></li><li><b>{`% - Percentage`}</b></li><li><b>{`∑ - Sum`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (Inbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess (Outbound)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess - Silence (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unsuccess - Voicemail (Click2Call)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      